<template>
    <div>
        <CCard class="shadow-sm m-2">
            <CCardBody>
                <CRow>
                    <CCol sm="1" col="2">
                        <img src="/img/icon/store.png" style="width: 32px; height:32px;"/>
                    </CCol>
                    <CCol sm="9" col="8">
                        <select id="addOptionGroupShopSelect" class="custom-select text-black" v-model="shop" disabled>
                            <option v-for="shop in shops" :key="shop.objectId" :value="shop">
                                {{ shop.shopName }} - {{ shop.branchName }}
                            </option>
                        </select>
                    </CCol>
                    <div class="col-md-2 col-sm-2 col-2 text-right" v-if="loadingButton == true">
                        <CButton id="addOptionGroupBackButton" block color="light" @click="goback()">
                            <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
                        </CButton>
                    </div>
                    <div class="col-md-2 col-sm-2 text-right" v-else>
                        <CButton id="addOptionGroupLoadingButton" block color="success" disabled>
                            <CSpinner color="white" size="sm" />{{ $t('loading') }}
                        </CButton>
                    </div>
                </CRow>
            </CCardBody>
        </CCard>
        <CCol md="12" lg="12" class="mt-4">
            <CCard class="shadow-sm">
                <CCardBody>
                    <CAlert id="addOptionGroupSuccessAlert" color="success" v-if="isSuccess === true">
                        {{ $t('createProductSuccess') }}
                    </CAlert>

                    <div class="row">

                        <div class="col-md-10 col-sm-9 col-9">
                            <h4 class="mt-2">
                                {{ $t('addOptionGroup') }}
                            </h4>
                            <CRow>
                                <CCol class="col-form-label" md="2"
                                    style="margin-left: 5%; margin-top: 1.5%; font-size: 17px;">{{ $t('txtOptionGroup')
                                    }}
                                </CCol>
                                <CCol style="margin-top: 2%;">
                                    <CInput id="addOptionGroupNameInput" v-if="validateName" v-model="groupName" :placeholder="$t('txtOptionGroup')"
                                        :is-valid="validator" :invalid-feedback="$t('txtOptionGroup')" />
                                    <CInput  id="addOptionGroupNameInputFallback" v-else v-model="groupName" :placeholder="$t('txtOptionGroup')" />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CCol class="col-form-label"
                                    style="margin-left: 5%; margin-top: 1.5%; font-size: 17px; width: auto;">{{
                            $t('require') }}
                                </CCol>
                                <CCol class="col-form-label align-items-center mt-2" md="1">
                                    <label class="container">
                                        <input id="addOptionGroupRequireCheckbox"  type="checkbox" v-model="isRequire" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </CCol>
                                <CCol class="col-form-label" md="2"
                                    style="margin-left: 5%; margin-top: 1.5%; font-size: 17px;">{{ $t('maximum') }}
                                </CCol>
                                <CCol style="margin-top: 2%; width: auto;">
                                    <CInput id="addOptionGroupMaxInput" v-model="maximum" :placeholder="$t('maximum')" />
                                </CCol>
                            </CRow>
                        </div>
                        <div class="col-md-2 col-sm-3 col-3 text-right">
                            <CButton id="addOptionGroupSaveButton" block color="success font-weight-normal" v-if="loadingSaveButton === false"
                                @click="handlePopup">
                                {{ $t('save') }}
                            </CButton>
                            <CButton id="addOptionGroupSaveLoadingButton" block color="success font-weight-normal" v-else-if="loadingSaveButton === true">
                                {{ $t('save') }}
                            </CButton>
                        </div>
                    </div>
                </CCardBody>

                <CCardBody>
                    <CAlert id="addOptionGroupAdditionalOptionsSuccessAlert" color="success" v-if="isSuccess === true">
                        {{ $t('createProductSuccess') }}
                    </CAlert>
                    <div class="row">
                        <div class="col-md-10 col-sm-9 col-9">
                            <h4 class="font-weight-normal text-dark">{{ $t('addOption') }}</h4>

                        </div>

                    </div>
                    <hr />

                    <CRow>
                        <CCol sm="6" lg="6" md="6">
                            <CCard class="cardadd">
                                <CCardHeader>
                                    <CRow>
                                        <CCol md="9" sm="9">
                                            <h5 class="font-weight-normal">
                                                {{ $t('optional') }}
                                            </h5>
                                        </CCol>
                                    </CRow>
                                </CCardHeader>
                                <CCardBody>
                                    <table id="addOptionGroupItemTable" class="table table-hover" style="width: 100%">
                                        <tbody>
                                            <div class="overflow-auto" style="max-height: 350px">
                                                <tr v-for="(item, index) in items" :key="index" hover border
                                                    class="cursor" striped @click="addBOMtoLits(item, index)">
                                                    <td :id="'addOptionGroupItemRow'+ index">
                                                <tr>
                                                    <span class="text-dark font-weight-normal">
                                                        {{ item.name }}
                                                    </span>
                                                </tr>
                                                <tr>
                                                    <small class="text-dark font-weight-normal">
                                                        {{ item.price }}
                                                    </small>
                                                </tr>
                                                </td>
                                                <td :id="'addOptionGroupItemRowIcon'+ index" style="width: 2%;" class="text-right text-dark">
                                                    <i class="fi fi-rr-angle-small-right"
                                                        @click="addBOMtoLits(item, index)"></i>
                                                </td>
                                                </tr>
                                            </div>
                                        </tbody>
                                    </table>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol sm="6" lg="6" md="6">
                            <CCard class="cardadd">
                                <CCardHeader>
                                    <CRow>
                                        <CCol md="9" sm="9">
                                            <h5 id="addOptionGroupSelectedOptionsTitle" class="font-weight-normal">
                                                {{ $t('addOption') }} ({{ countAddList }})
                                            </h5>
                                        </CCol>
                                    </CRow>
                                </CCardHeader>
                                <CCardBody>
                                    <div v-if="loadingButton == true" class="overflow-auto" style="max-height: 350px;">
                                        <table id="addOptionGroupSelectedOptionsTable" class="table table-hover" style="width: 100%">
                                            <tbody>
                                                <tr v-for="(item, index) in addlist" :key="index" hover border striped>
                                                    <td :id="'addOptionGroupSelectedOptionDataCol'+ index">
                                                <tr>
                                                    <span class="font-weight-normal text-dark">
                                                        {{ item.optionItem.optionItemName }}
                                                    </span>
                                                </tr>
                                                <tr>
                                                    <small class="font-weight-normal text-dark">
                                                        {{ item.optionItem.optionItemPrice }}
                                                    </small>
                                                </tr>
                                                </td>
                                                <td style="width: 5%" class="text-center">
                                                    <img :id="'addOptionGroupSelectedOptionRemoveIcon'+ index" src="/img/trash.png" style="width: 14px; height: 14px; cursor: pointer; "   @click="conremoveRow(item, index)">
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-else class="text-center overflow-auto" width="100%"
                                        style="min-height: 300px; max-height: 300px">
                                        <button class="btn " type="button" disabled style="color: darkcyan;">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            {{ $t('loading') }}
                                        </button>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </CCol>

                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
        <modal id="addOptionGroupConfirmModal" color="success" :show.sync="confirmModal" :title="$t('confirmSaveImg')" v-on:isSave="savedata" centered
            :loading="loading">
            <div>
                <h4 class="mt-4 mb-4 text-center">{{ $t('confirmSaveProduct') }}</h4>
            </div>
        </modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import local from '@/services/local'
import permis from '@/util/permission'

export default {
    components: {},
    data() {
        return {
            isSuccess: false,
            SKUName: '',
            keyword: '',
            data: [],
            dataOption: [],
            validateShop: false,
            shop: this.$store.getters.shopObjectId,
            addlist: [],
            quantity: 1,
            countAddList: 0,
            loadingButton: true,
            confirmModal: false,
            name: '',
            itemId: '',
            itemObjectId: '',
            shop: this.$store.getters.shopObjectId,
            sortdata: 'name',
            loadingSaveButton: false,
            validateName: '',
            validatePrice: '',
            groupObjectId: '',
            groupName: '',
            loading: true,
            group: [],
            maximum: null,
            isRequire: false,
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date']),
        isPermission() {
            let path = '/product'
            return permis.findPermissionRead('product', path)
        },
        isEditData() {
            let path = '/product'
            return permis.findPermissionEdit('product', path)
        },
        isDeleteData() {
            let path = '/product'
            return permis.findPermissionRemove('product', path)
        },
        uid() {
            let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
            return shop.uid
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        fields() {
            return [{ key: 'name', label: 'ชื่อสินค้า' }]
        },
        items() {
            let detail = []
            for (let i = 0; i < this.dataOption.length; i++) {
                let price = 0
                if (typeof this.dataOption[i].price === 'number') {
                    price = this.dataOption[i].price.toFixed(2)
                } else {
                    price = '-'
                }
                detail.push({
                    name: this.dataOption[i].name,
                    id: this.dataOption[i].id,
                    price: price,
                    objectId: this.dataOption[i].objectId,
                })
            }
            return detail
        },
        noImg() {
            return process.env.VUE_APP_NOIMAGE
        },
    },
    created() {
        this.getProductOption()
        this.shop = this.shops.find((i) => i.objectId === this.shop)
    },
    methods: {
        handleSwitchChange(newValue) {
            this.isRequire = newValue;
        },
        goback() {
            this.$router.go(-1)
        },
        conremoveRow: function (item, index) {
            var con = confirm(this.$i18n.t('confirmSaveProduct'));
            if (con == true) {
                this.addlist.splice(index, 1);
                this.items.push({
                    objectId: item.optionItem.optionItemObjectId,
                    id: item.optionItem.optionItemId,
                    name: item.optionItem.optionItemName,
                    price: item.optionItem.optionItemPrice,
                });
                this.countAddList = this.addlist.length;
            }
        },
        confirmSaveProductBOM() {
            this.loading = true
            this.confirmModal = true
        },
        addBOMtoLits(item, index) {
            this.addlist.push({
                optionItem: {
                    optionItemObjectId: item.objectId,
                    optionItemId: item.id,
                    optionItemName: item.name,
                    optionItemPrice: item.price
                }
            })
            this.items.splice(index, 1)
            this.countAddList = this.addlist.length
        },
        getProductOption(page = 1) {
            this.searchLoadingButton = false;
            this.loadingButton = false;
            const uid = `${localStorage.getItem('shopsUid')}`;
            const shopObjectId = this.shopObjectId;
            const params = { page: page, limit: 50, shopObjectId: shopObjectId };
            const headers = { shopObjectId: shopObjectId };

            local({
                url: '/api/V1.0/' + uid + '/option/item/get/',
                params: params,
                headers: headers,
                method: 'GET',
            }).then((res) => {
                this.dataOption = res.data.data;
                this.loadingButton = true;
            });
        },
        savedata() {
            this.loadingSaveButton = true
            this.loadingeButton = true
            this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = this.shopObjectId
            let data = []
            function generateUniqueID() {
                const characters = 'abcdef0123456789';
                let id = '';
                for (let i = 0; i < 24; i++) {
                    id += characters[Math.floor(Math.random() * characters.length)];
                }
                return id;
            }
            if (this.groupObjectId == '') {
                data = [
                    {
                        objectId: this.groupObjectId,
                        name: this.groupName,
                        uid: uid,
                        shop: {
                            objectId: this.shop.objectId,
                            id: this.shop.id,
                            branchName: this.shop.branchName,
                        },
                        require: this.isRequire,
                        maximum: parseInt(this.maximum),
                        items: this.addlist.map((item) => {
                            const generatedObjectId = generateUniqueID();
                            const generatedId = generateUniqueID();
                            return {
                                optionGroup: {
                                    name: this.groupName,
                                    id: this.groupId,
                                    objectId: this.groupObjectId,
                                },
                                orderIndex: this.addlist.indexOf(item),
                                objectId: generatedObjectId,
                                id: generatedId,
                                optionItem: {
                                    id: item.optionItem.optionItemId,
                                    objectId: item.optionItem.optionItemObjectId,
                                    name: item.optionItem.optionItemName,
                                    price: item.optionItem.optionItemPrice,
                                },
                            };
                        }),
                    }
                ]
            } else {
                data = [
                    {
                        objectId: this.groupObjectId,
                        name: this.groupName,
                        uid: uid,
                        shop: {
                            objectId: this.shop.objectId,
                            id: this.shop.id,
                            branchName: this.shop.branchName,
                        },
                        maximum: parseInt(this.maximum),
                        require: this.isRequire,
                        items: this.addlist.map(item => {
                            const generatedObjectId = generateUniqueID();
                            const generatedId = generateUniqueID();
                            return {
                                optionGroup: {
                                    name: this.groupName,
                                    id: this.groupId,
                                    objectId: this.groupObjectId
                                },
                                orderIndex: this.addlist.indexOf(item),
                                objectId: generatedObjectId,
                                id: generatedId,
                                optionItem: {
                                    id: item.optionItem.optionItemId,
                                    objectId: item.optionItem.optionItemObjectId,
                                    name: item.optionItem.optionItemName,
                                    price: item.optionItem.optionItemPrice
                                },
                            };
                        }),
                    }
                ]
            }
            const headers = { shopObjectId: shopObjectId }

            local({
                method: 'post',
                url: '/api/V1.0/' + uid + '/option/group/update',
                data: data,
                headers: headers,
            })
                .then(
                    setTimeout(() => {
                        this.loadingSaveButton = false
                        this.name = ''
                        this.loadingButton = false
                        this.confirmModal = false,
                        this.$router.push('/product/optiongroup')

                    }, 1000)
                )
                .catch((error) => {
                    console.log(error)
                    this.loadingSaveButton = false
                })
        },
        validator(val) {
            return val ? val.length >= 1 : false
        },
        handlePopup() {
            if (this.groupName === '') {
                this.validateName = 'กรุณากรอกชื่อกลุ่มตัวเลือก'
            } else {
                this.validateName = ''
                this.savedata()
            }
        },
    },
}
</script>

<style>
.cardadd {
    min-height: 438px;
    max-height: 400px;
    border-color: #3abc98;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 27px;
  width: 27px;
  border: solid #ced2d8;
  border-radius: 5px;
  background-color: white ;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #3abc98;
  border: solid #3abc98;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 4px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

</style>